<template>
  <div class="rising-box">
    <h1 class="text-uppercase common-text"><template v-if="isZealandFlow">HIGH FIVE!</template><template v-else>CONGRATULATIONS</template></h1>
    <p><template v-if="isZealandFlow">Du er nu</template>
<template v-else>Your profile level is now</template></p>
    <h1 class="banner-header">RISING STAR</h1>
    <img
      src="~@/assets/arrow-big-purple.png"
      alt="search icon"
      class="m-26"
      srcset=""
    />
    <h4 class="black font-28"><template v-if="isZealandFlow">Nye features låst op</template>
<template v-else>You have unlocked</template></h4>
    <h4 v-if="!isZealandFlow" class="pink">Thesis Collaboration Matching</h4>
    <h4
      v-if="isZealandFlow"
      class="welcome-desc black font-weight-normal container-narrow mx-auto mb-5"
    >
      Stærkt gået! Du kan nu finde virksomheder til projektsamarbejder og
      deltage i karriereevents kun for Exceleraters - præcis ligesom dig.
      <br /><br />
      <strong>Klar til at gå hele vejen?</strong>
      <br />
      Udfyld din Excelerater profil til Talent for at skille dig ud og få adgang
      til vores Karriere Matching feature.
    </h4>
    <h4
      v-else
      class="welcome-desc black font-weight-normal container-narrow mx-auto mb-5"
    >
      You can now upload your thesis topic / project proposal so we can match
      you with relevant companies.
      <br /><br />
      Ready to stand out from the crowd? Fill in your work experience and
      profile bio to upgrade your profile to the next level and stand out.
    </h4>

    <rising-star-progress-bar :currentValue="100" />
    <div>
      <button
        class="btn w-430 mt-5 btn-primary RisingStar-Congratulations-Next"
        @click="handleTalent"
      >
        <template v-if="isZealandFlow">Excelerater Talent</template
        ><template v-else>Stand out with a Talent profile</template>
      </button>
    </div>
    <div>
      <router-link
        to="/"
        class="btn w-430 mb-5 mt-2 btn-outline-purple RisingStar-Congratulations-Dashboard"
      >
        <template v-if="isZealandFlow">Gå til Excelerater dashboard</template>
        <template v-else>Go to Rising Star dashboard</template>
      </router-link>
    </div>
  </div>
</template>

<script>
import RisingStarProgressBar from "../../RisingStarProgressBar.vue";
export default {
  components: { RisingStarProgressBar },
  name: "CongratulationsRisingStar",
  props: ["handleTalent"],
  computed: {
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  },
};
</script>

<style scoped>
.banner-header {
  color: #8b2472;
  font-size: 54px;
  font-weight: bold;
}

.m-26 {
  margin-top: 26px;
  margin-bottom: 30px;
}

.font-28 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
}

.pink {
  color: #bc1e73;
}

.btn-outline-purple {
  border: 2px solid #5a2871;
  color: #5a2871;
}

/* .rising-box{
  padding: 0px 30px;
} */
.welcome-desc {
  margin-top: 25px;
}

.w-430 {
  width: 430px;
}

/* .common-texts{
  font-size: 24px;
} */
@media (max-width: 767px) {
  /* .rising-box{
    padding: 0px 0px 0px 0px !important;
  } */
  .welcome-desc {
    margin-bottom: 60px !important;
  }

  .w-430 {
    width: 100%;
  }
}
</style>
