<template>
  <div class="">
    <div class="pj-loading" v-if="!user">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <div class="" v-else>
      <h1 class="text-uppercase common-text">
        <template v-if="isZealandFlow">
          DIN TALENT BIO
        </template>
        <template v-else>
          YOUR TALENT BIO
        </template>
      </h1>
      <rising-star-progress-bar :currentValue="progress"></rising-star-progress-bar>
      <h4 class="welcome-desc font-weight-normal container-narrow mx-auto">
        <template v-if="isZealandFlow">
          Fortæl os om dig selv for at skille dig ud fra mængden og tiltrække de rette virksomheder.
        </template>
        <template v-else>
          Tell us a bit more about yourself to stand out from the crowd and attract
          the right companies.
        </template>
      </h4>
      <h4 class="welcome-desc font-weight-normal mt-4 container-narrow mx-auto">
        <template v-if="isZealandFlow">
          Undlad dit navn, alder, køn og nationalitet for at sikre en upartisk matchning.
        </template>
        <template v-else>
          Leave out your name, age, gender, and nationality to ensure unbiased
          matching.
        </template>

      </h4>
      <form @submit.prevent="validateFields">
        <div class="form-group text-left mt-4">
          <label for="">
            <template v-if="isZealandFlow">
              Profil bio
            </template>
            <template v-else>
              Profile bio
            </template>
          </label>
          <plain-text-editor v-if="isZealandFlow"
            placeholder="Beskriv dig selv. Hvad laver du i din fritid? Hvilke interesser og hobbier har du?" :limit="1000"
            v-model="formData.profileBio"></plain-text-editor>
          <plain-text-editor v-else
            placeholder="Describe yourself. What do you like to do in your free time? What are your interests and hobbies?"
            :limit="1000" v-model="formData.profileBio"></plain-text-editor>
          <div class="alert alert-danger mt-3" v-if="bioError">
            {{ bioError }}
          </div>
        </div>
        <div class="form-group text-left">
          <label for="">
            <template v-if="isZealandFlow">
              Passion og motivation
            </template>
            <template v-else>
              Passion and motivation
            </template>
          </label>
          <plain-text-editor v-if="isZealandFlow"
            placeholder="Hvad går du op i? Hvad er din passion og hvad motiverer dig? Hvorfor er netop du den rigtige for en kommende arbejdsgiver?"
            :limit="1000" v-model="formData.passionAndMotivation"></plain-text-editor>
          <plain-text-editor v-else
            placeholder="What are you passionate about and what motivates you? Why are you the right person for a company?"
            :limit="1000" v-model="formData.passionAndMotivation"></plain-text-editor>
          <div class="alert alert-danger mt-3" v-if="motivationError">
            {{ motivationError }}
          </div>
        </div>
        <div class="alert alert-danger mt-3" v-if="errorText || userErrorMessage">
          {{ errorText || userErrorMessage }}
        </div>
        <div class="text-right mt-5">
          <!-- <button class="btn btn-link Explorer-YourStudyBackground-Back" @click.prevent="handleBack">
          <i class="fas fa-chevron-left"></i> Back
        </button> -->
          <button class="btn btn-outline mr-2 Talent-TalentBio-Skip w-200" @click.prevent="handleSkip">
            <template v-if="isZealandFlow">
              Spring over
            </template>
            <template v-else>
              Skip
            </template>
          </button>
          <button class="btn btn-primary Talent-TalentBio-Next w-200" :disabled="isUpdating">
            <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-else>
              <template v-if="isZealandFlow">
                Næste
              </template>
              <template v-else>
                Next
              </template>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { base64ToString } from '../../../utils/utils';
import plainTextEditor from '../../plainTextEditor.vue';
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';

let userId = localStorage.getItem('uid')
userId = base64ToString(userId)


export default {
  components: { plainTextEditor, RisingStarProgressBar },
  props: ['progress', 'handleBack', 'handleSkip'],
  data() {
    return {
      bioError: '',
      motivationError: '',
      errorText: '',
      formData: {
        profileBio: '',
        passionAndMotivation: '',
      },
    };
  },
  computed: {
    userErrorMessage() {
      return this.$store.getters.userErrorMessage ? 'Something unexpected happened' : null;
    },
    isUpdating() {
      return this.$store.getters.userIsUpdating;
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    user() {
      // let res = false
      const vm = this
      const user = this.$store.getters.studentData
      if (user) {
        vm.formData.profileBio = user.profileBio
        vm.formData.passionAndMotivation = user.passionAndMotivation
      }
      return user;
    }
  },
  methods: {
    validateFields() {
      const vm = this;
      vm.bioError = '';
      vm.motivationError = '';
      vm.errorText = '';
      if (!vm.formData.profileBio) {
        vm.bioError = 'Please provide profile bio.';
      }
      if (!vm.formData.passionAndMotivation) {
        vm.motivationError = 'Please provide passion and motivation.';
      }
      if (vm.formData.profileBio.length < 20) {
        vm.bioError = 'Please provide more information for profile bio';
      }
      if (vm.formData.passionAndMotivation.length < 20) {
        vm.motivationError =
          'Please provide more information for passion and motivation';
      }
      if (
        vm.formData.passionAndMotivation.length > 2000 ||
        vm.formData.profileBio.length > 2000
      ) {
        vm.errorText = 'Maximum 2000 characters allowed';
      }
      if (vm.bioError || vm.motivationError || vm.errorText) return
      // vm.handleSubmit('talent bio');
      this.$store.dispatch('updateStudentTalentBio', { ...this.formData, currentProgress: +this.user.currentProgress + 20 }).then(() => {
        this.$store.dispatch('getStudentData', userId).then(() => {
          this.$emit('handle-submit', 'talent bio')
        }).catch(() => console.log('errror'))
      }).catch(() => console.log('errror'))
    },
  },
};
</script>

<style scoped>
form {
  padding: 0 !important;
}

@media (max-width: 767px) {
  .w-200 {
    /* width: 120px; */
    margin-top: 20px;
  }

  .btn-outline {
    padding-left: 0;
    padding-right: 0;
  }

  .common-text {
    font-size: 24px;
    margin-bottom: 50px;
  }
}
.btn-outline{
  padding: 0.4rem  !important;
}
</style>
