<template>
  <div class="rising-box">
    <h1 class="text-uppercase black common-text">
      <template v-if="isZealandFlow">
        HIGH FIVE!
      </template>
      <template v-else>
        CONGRATULATIONS
      </template>
    </h1>
    <p>
      <template v-if="isZealandFlow">
        Du er nu
      </template>
      <template v-else>
        Your profile level is now
      </template>
    </p>
    <h1 class="banner-header">TALENT</h1>
    <img src="~@/assets/star-big-purple.png" alt="search icon" class="m-26" srcset="" />
    <h4 class="black font-28">
      <template v-if="isZealandFlow">
        Ny feature låst op
      </template>
      <template v-else>
        You can now unlock
      </template>
    </h4>
    <h4 class="pink">
      <template v-if="isZealandFlow">
        Excelerate Karriere Matching
      </template>
      <template v-else>
        Excelerate Career Matching
      </template>
    </h4>
    <router-link class="btn btn-primary w-200 Talent-Congratulations-Unlock" to="/user/student-talent/unlock">
      <template v-if="isZealandFlow">
        Aktiver feature nu
      </template>
      <template v-else>
        Unlock feature now
      </template>
    </router-link>
    <h4 class="welcome-desc black font-weight-normal container-narrow mx-auto">
      <template v-if="isZealandFlow">
        High five, Excelerater! Du er nu Talent og har adgang til alle fordele og features på Excelerate.
      </template>
      <template v-else>
        Amazing job, Excelerater! You have completed your Talent profile and can now access all features and benefits on
        Excelerate.
      </template>
      <br><br>
      <strong class="text-center">
        <template v-if="isZealandFlow">
          Er du på udkig efter din næste karrieremulighed?
        </template>
        <template v-else>
          Looking for your next career opportunity?
        </template>
      </strong>
      <br>
      <template v-if="isZealandFlow">
        Udforsk ledige stillinger, praktikpladser, projektsamarbejder og meget mere på vores hjemmeside nedenfor. God
        fornøjelse!
      </template>
      <template v-else>
        Explore open positions, internships, project collaborations, and much more on our website below. We promise it
        will
        be worth it.
      </template>
    </h4>
    <rising-star-progress-bar :currentValue="200" />
    <div>
      <router-link class="btn mt-5 btn-primary w-430 Talent-Congratulations-UploadProject"
        to="/events/Zealand-Job--og-Karrieredag-2024">
        <template v-if="isZealandFlow">
          Ansøg og match med virksomheder
        </template>
        <template v-else>
          Upload thesis topic / project proposal
        </template>
      </router-link>
    </div>
    <div>
      <router-link to="/" class="btn mb-5 mt-2 w-430 btn-outline-purple Talent-Congratulations-Dashboard">
        <template v-if="isZealandFlow">
          Gå til Excelerater dashboard
        </template>
        <template v-else>
          Go to Talent dashboard
        </template>
      </router-link>
    </div>
  </div>
</template>

<script>
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';
export default {
  components: { RisingStarProgressBar },
  name: 'CongratulationsTalent',
  props: ['handleTalent'],
  computed: {
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  }
};
</script>

<style scoped>
.banner-header {
  color: #BC1E73;
  font-size: 54px;
  font-weight: bold;
}

.m-26 {
  margin-top: 26px;
  margin-bottom: 30px;
}

.font-28 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
}

.pink {
  color: #BC1E73;
}

.btn-outline-purple {
  border: 2px solid #5A2871;
  color: #5A2871;
}

.w-430 {
  width: 430px;
}

/* 
 */
.welcome-desc {
  margin-top: 25px;
  margin-bottom: 50px;
}

@media(max-width: 767px) {

  /* .rising-box{
    padding: 50px 0px 30px 0px !important;
  } */
  .w-430 {
    width: 100%;
  }
}
</style>

