<template>
  <div>
    <student-header />
    <div class="user-flow-logo">
      <div class="user-flow">
        <div class="pj-loading" v-if="!isLoaded"><i class="fas fa-circle-notch fa-spin"></i></div>
        <div class="row" v-else>
          <student-profile-overview v-if="step === 1" :handleSubmit="handleSubmit" :width="width" />
          <!-- :progress="userDetails.currentProgress * 2" -->
          <student-star @update-width="updateWidth" v-if="step === 2" />
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <router-link to="/" class="text-decoration-none pink-color font-weight-bold mb-5 m20">
          <template v-if="isZealandFlow">
            Tilbage til dashboard
          </template>
          <template v-else>
            Go to Dashboard
          </template>
          <img src="~@/assets/hus.png" alt="" srcset="" class="home_img"></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import StudentProfileOverview from '../../components/student/StudentProfileOverview.vue';
import StudentHeader from '../../components/student/StudentHeader.vue';
import StudentStar from '../../components/student/StudentStar.vue';
import { base64ToString } from '../../utils/utils';

const userId = base64ToString(localStorage.getItem('uid'))

export default {
  data: function () {
    return {
      step: 1,
      width: '100%',
      // width: '100%',
    };
  },
  components: {
    StudentProfileOverview,
    StudentHeader,
    StudentStar,
  },
  mounted() {
    var vm = this;
    vm.$store.dispatch('setSignupData', 'Student');
    if (!this.$store.getters.studentData) {
      this.$store.dispatch('getStudentData')
    }
  },
  computed: {
    user() {
      return this.$store.getters.studentData
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    isLoaded() {
      var vm = this;
      var result = false;
      if (!this.user) return false
      if (
        vm.user.isCourses ||
        vm.user.isCompetencies ||
        vm.user.isTalent ||
        vm.user.isExp
      ) {
        vm.step = 2;
      }
      return true;
    },
  },
  methods: {
    handleSubmit: function () {
      this.step++;
    },
    updateWidth(val) {
      this.width = val;
    },
  },
};
</script>

<style>
.btn-outline {
  border: 1px solid #d22d89;
  color: #d22d89;
  padding: 0.4rem 4rem;
  font-size: 16px;
  font-weight: 600;
}

.bottom_flex {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: end;
  margin-top: 10px;
}

.home_img {
  max-width: 20px;
}

@media (min-width: 499px) {
  /* .congratulations-button{
    max-width: 430px;
    margin:auto;
  } */
}
</style>
<style scoped>
.user-flow {
  max-width: 100% !important;
}

.m20 {
  margin-top: 20px
}

.pj-loading {
  padding: 20%
}
</style>
