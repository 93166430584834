import { render, staticRenderFns } from "./Competencies.vue?vue&type=template&id=590c5ad0"
import script from "./Competencies.vue?vue&type=script&lang=js"
export * from "./Competencies.vue?vue&type=script&lang=js"
import style0 from "./Competencies.vue?vue&type=style&index=0&id=590c5ad0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports