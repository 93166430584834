<template>
  <div class="" v-show="isLoaded">
    <!-- <div
      class="pj-loading"
      v-if="hardSkillsData.length === 0 && coreSkillsData.length === 0"
    >
      <i class="fas fa-circle-notch fa-spin"></i>
    </div> -->
    <div class="" v-if="!user"></div>
    <div class="" v-else>
      <h1 class="text-uppercase common-text">
        <template v-if="isZealandFlow">
          DINE KOMPETENCER
        </template>
        <template v-else>
          {{ pageContent && pageContent.formCompetenciesHeader ? pageContent.formCompetenciesHeader : 'YOUR COMPETENCIES' }}
        </template>
      </h1>
      <rising-star-progress-bar :currentValue="progress"></rising-star-progress-bar>
      <h4 class="welcome-desc font-weight-normal container-narrow mx-auto">
        <template v-if="isZealandFlow">
          Fortæl os om dine bedste kompetencer. Tilføj 5 soft skills og 5 hard skills i prioriteret rækkefølge.
        </template>
        <template v-else>
          {{ pageContent && pageContent.formCompetenciesSubHeader ? pageContent.formCompetenciesSubHeader : 'Tell us a bit more about your competencies. Add 5 soft and 5 hard skills in a prioritized order.' }}
        </template>
      </h4>
      <div class="row" v-if="user">
        <div class="col-md-6">
          <h4 class="text-left mt-5">
            {{ pageContent && pageContent.formSoftSkillsHeader ? pageContent.formSoftSkillsHeader : 'Soft skills' }}</h4>
          <competence-core-skills v-for="(item, index) in skillArray" :skill="item" :id="index + 1"
            v-bind:key="`core-${item}`" :placeholder="isZealandFlow ? danishCoreSkillTitleArray : coreSkillTitleArray"
            @custom-core-skill="addNewCoreSkill" v-bind:skills="formData.coreSkills"
            v-on:update-core-skill="updateCoreSkill" :skillData="coreSkillsData"></competence-core-skills>
        </div>
        <div class="col-md-6">
          <h4 class="text-left mt-5">Hard skills</h4>
          <competence-hard-skills v-for="(item, index) in skillArray" v-bind:key="`hard-${item}`" :skill="item"
            @custom-hard-skill="addNewHardSkill"
            :placeholder="isZealandFlow ? danishHardSkillTitleArray : hardSkillTitleArray" :id="index + 1"
            v-bind:skills="formData.hardSkills" v-on:update-hard-skill="updateHardSkill" :skillData="hardSkillsData">
          </competence-hard-skills>
        </div>
      </div>
      <div class="alert alert-danger mt-3" v-if="errorText">
        {{ errorText }}
      </div>
      <!-- <div class="d-flex justify-content-between mt-5"> -->
      <!-- <button class="btn btn-link Explorer-YourStudyBackground-Back" @click.prevent="handleBack">
          <i class="fas fa-chevron-left"></i> Back
        </button> -->
      <div class="text-right mt-4">
        <button class="btn btn-outline btn-new-outline mr-2 RisingStar-Competencies-Skip w-200"
          @click.prevent="handleSkip">
          <template v-if="isZealandFlow">
            Spring over
          </template>
          <template v-else>
            Skip
          </template>
        </button>
        <button class="btn btn-primary RisingStar-Competencies-Next w-200" @click="validateSkills" :disabled="isUpdating">
          <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else>
            <template v-if="isZealandFlow">
              Næste
            </template>
            <template v-else>
              Next
            </template>
          </span>
        </button>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CompetenceHardSkills from './CompetenceHardSkills.vue';
import CompetenceCoreSkills from './CompetenceCoreSkills.vue';
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';
import generalService from '../../../services/generalService';
import { base64ToString } from '../../../utils/utils';

let userId = localStorage.getItem('uid')
userId = base64ToString(userId)

export default {
  components: {
    CompetenceHardSkills,
    CompetenceCoreSkills,
    RisingStarProgressBar,
  },
  props: ['progress', 'handleBack', 'handleSkip'],
  data() {
    return {
      formData: {
        coreSkills: {
          skill_one: '',
          skill_two: '',
          skill_three: '',
          skill_four: '',
          skill_five: '',
        },
        hardSkills: {
          skill_one: '',
          skill_two: '',
          skill_three: '',
          skill_four: '',
          skill_five: '',
        }
      },
      hardSkillsData: [],
      coreSkillsData: [],
      skillArray: [
        'skill_one',
        'skill_two',
        'skill_three',
        'skill_four',
        'skill_five',
      ],
      coreSkillTitleArray: [
        'E.g. Critical thinking',
        'E.g. Problem-solving',
        'E.g. Adaptability',
        'E.g. Teamwork',
        'E.g. Leadership',
      ],
      hardSkillTitleArray: [
        'E.g. UI/UX design',
        'E.g. HTML/CSS/JS',
        'E.g. Business and data analysis',
        'E.g. Social Media',
        'E.g. Project management',
      ],
      danishCoreSkillTitleArray: [
        'F.eks. Kritisk tænkning',
        'F.eks Problemløsning',
        'F.eks. Tilpasningsparathed',
        'F.eks. Teamwork',
        'F.eks. Empati',
      ],
      danishHardSkillTitleArray: [
        'F.eks. UI/UX Design',
        'F.eks. Python',
        'F.eks. Forretningsanalyse',
        'F.eks. Projektledelse',
        'F.eks. Salg',
      ],
      errorText: '',
      customSkills: {
        softSkills: [],
        hardSkills: [],
      },
    };
  },
  created() {
    if (!this.$store.getters.allSkills.hardSkills || !this.$store.getters.allSkills.softSkills) {
      this.$store.dispatch('getAllSkills').then(() => {
        this.hardSkillsData = this.$store.getters.allSkills.hardSkills;
        this.coreSkillsData = this.$store.getters.allSkills.softSkills;
      })
    } else {
      this.hardSkillsData = this.$store.getters.allSkills.hardSkills;
      this.coreSkillsData = this.$store.getters.allSkills.softSkills;
    }
  },
  computed: {
    isUpdating() {
      return this.$store.getters.userIsUpdating;
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    user() {
      const vm = this;
      const userData = this.$store.getters.studentData
      if (userData) {
        if (userData.coreSkill) {
          vm.formData.coreSkills = userData.coreSkill
        }
        if (userData.hardSkill) {
          vm.formData.hardSkills = userData.hardSkill
        }
      }
      // if(this.$store.getters.studentData.coreSkill) vm.formData.coreSkills = JSON.parse(this.$store.getters.studentData.coreSkill)
      return this.$store.getters.studentData
    },
    pageContent() {
      return this.$store.getters.risingCompetenciesContent
    },
    isLoaded() {
      var vm = this;
      return true;
    },
  },
  methods: {
    // the below two methods update the formData object with the new values emitted from the child components
    updateCoreSkill(skillName, skillValue) {
      this.formData.coreSkills[skillName] = skillValue
    },
    updateHardSkill(skillName, skillValue) {
      this.formData.hardSkills[skillName] = skillValue
    },
    // the below two methods add custom skills that are then saved in our database
    addNewHardSkill(skill) {
      this.customSkills.hardSkills.push(skill);
    },
    addNewCoreSkill(skill) {
      this.customSkills.softSkills.push(skill);
    },
    validateSkills() {
      this.errorText = '';
      this.testObject(this.formData.hardSkills);
      this.testObject(this.formData.coreSkills);
      if (!this.errorText) {
        // this.updateformData();
        // this.$emit(
        //   'handle-submit',
        //   'competencies',
        //   this.formData.hardSkills,
        //   this.formData.coreSkills
        // );
        const currentProgress = this.user.isCompetencies ? +this.user.currentProgress : +this.user.currentProgress + 20
        this.$store.dispatch('updateStudentCompetencies', { competencies: this.formData, currentProgress, step: 1 }).then(() => {
          this.$store.dispatch('getStudentData', userId).then(() => {
            this.$emit('handle-submit', 'competencies')
          })
        })
        if (
          this.customSkills.hardSkills.length > 0 ||
          this.customSkills.softSkills.length > 0
        ) {
          this.updateCustomSkills();
        }
      }
    },
    async updateCustomSkills() {
      await generalService.addCustomSkills({ ...this.customSkills })
    },
    testObject(obj) {
      if (typeof obj == typeof undefined) {
        this.errorText = 'Please fill all fields to proceed';
        return;
      }
      for (let key in obj) {
        if (obj[key] === '') {
          this.errorText = 'Please fill all fields to proceed';
        }
      }
    },
  },
};
</script>

<style>
.btn-new-outline {
  padding: 0.375rem 0.75rem !important;
  width: 200px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .btn-new-outline {
    width: 120px;
  }

  .w-200 {
    width: 120px;
    margin-top: 20px;
  }
}
</style>
