<template>
  <div class="col-12 user-form">
    <div class="" v-if="isLoading">loading...</div>
    <div class="" v-else>
      <div
        v-if="isZealandFlow"
        class="d-flex justify-content-around align-items-center bg-grey overview-header text-white mb-5 hide-mobile"
      >
        <div class="flex-column">
          <h4 class="">
            Studiebaggrund
          </h4>
          <span>30%</span>
        </div>
        <div class="flex-column">
          <h4 class="">
            Kompetencer
          </h4>
          <span>20%</span>
        </div>
        <div class="flex-column">
          <h4 class="">
            Arbejdserfarring
          </h4>
          <span>30%</span>
        </div>
        <div class="flex-column">
          <h4 class="">
            Talent bio
          </h4>
          <span>20%</span>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-content-around align-items-center bg-grey overview-header text-white mb-5 hide-mobile"
      >
        <div class="flex-column">
          <h4 class="">
            {{
              pageContent && pageContent.studyTab
                ? pageContent.studyTab
                : "Study Background"
            }}
          </h4>
          <span>30%</span>
        </div>
        <div class="flex-column">
          <h4 class="">
            {{
              pageContent && pageContent.competenciesTab
                ? pageContent.competenciesTab
                : "Competencies"
            }}
          </h4>
          <span>20%</span>
        </div>
        <div class="flex-column">
          <h4 class="">
            {{
              pageContent && pageContent.workExperienceTab
                ? pageContent.workExperienceTab
                : "Work experience"
            }}
          </h4>
          <span>30%</span>
        </div>
        <div class="flex-column">
          <h4 class="">
            {{
              pageContent && pageContent.talentBioTab
                ? pageContent.talentBioTab
                : "Talent bio"
            }}
          </h4>
          <span>20%</span>
        </div>
      </div>
      <div class="spacing">
        <h1 v-if="isZealandFlow" class="text-uppercase mobile-bottom-space">
          PROFILOVERSIGT
        </h1>
        <h1 v-else class="text-uppercase mobile-bottom-space">
          PROFILE COMPLETION OVERVIEW
        </h1>
        <rising-star-progress-bar :currentValue="0" />
        <!-- setting the progress to 0 manually to remove any issues with the rendering on colored icons -->
        <h4 v-if="isZealandFlow" class="welcome-desc font-weight-normal container-narrow mx-auto">
          Udfyld din Excelerater profil og få adgang til fordele og features på 
          platformen. Vi lover dig, at du ikke kommer til at fortryde det.
        </h4>
        <h4 v-else class="welcome-desc font-weight-normal container-narrow mx-auto">
          {{
            pageContent && pageContent.formSubHeader
              ? pageContent.formSubHeader
              : "Fill in more information about yourself to unlock more features and benefits on Excelerate."
          }}
        </h4>
        <div class="parent_box">
          <div class="d-flex text-left mobile-flex mt-4">
            <img
              v-if="pageContent && pageContent.formRisingImage"
              :src="'pageContent.formRisingImage'"
              alt=""
              srcset=""
            />
            <img v-else src="~@/assets/arrow-big-purple.png" alt="" srcset="" />
            <div class="">
              <h3 class="purple-color">
                {{
                  pageContent && pageContent.formRisingTitle
                    ? pageContent.formRisingTitle
                    : "Rising Star"
                }}
              </h3>
              <ul v-if="isZealandFlow" class="text-left pl-0">
                <li>
                  <i class="fa fa-check purple-color" aria-hidden="true"></i>
                  Deltag i karriereevents kun for Exceleraters
                </li>
                <li>
                  <i class="fa fa-check purple-color" aria-hidden="true"></i>
                  Udforsk virksomheder til projektsamarbejder
                </li>
                <li>
                  <i class="fa fa-check purple-color" aria-hidden="true"></i>
                  Få sparring fra en Excelerate karriereekspert
                </li>
              </ul>
              <template v-else>
                <ul
                  class="text-left pl-0"
                  v-if="risingListContent && risingListContent.length > 0"
                >
                  <li v-for="item in risingListContent" :key="item.id">
                    <i class="fa fa-check purple-color" aria-hidden="true"></i>
                    {{ getRisingListItem(item) }}
                  </li>
                </ul>
                <ul v-else class="text-left pl-0">
                  <li>
                    <i class="fa fa-check purple-color" aria-hidden="true"></i>
                    Unlock Excelerate Thesis Collaboration Matching
                  </li>
                  <li>
                    <i class="fa fa-check purple-color" aria-hidden="true"></i>
                    Match with companies in your industry
                  </li>
                  <li>
                    <i class="fa fa-check purple-color" aria-hidden="true"></i>
                    Upload thesis topic / project proposal
                  </li>
                </ul>
              </template>
            </div>
          </div>
          <div class="d-flex text-left mobile-flex mt-4">
            <img
              :src="'pageContent.formTalentImage'"
              v-if="pageContent && pageContent.formTalentImage"
              alt=""
              srcset=""
            />
            <img src="~@/assets/star-big-purple.png" v-else alt="" srcset="" />
            <div class="">
              <h3 class="pink-color">Talent</h3>
              <ul v-if="isZealandFlow" class="text-left pl-0">
                <li>
                  <i class="fa fa-check purple-color" aria-hidden="true"></i>
                  3x dine praktik- og jobmatching chancer
                </li>
                <li>
                  <i class="fa fa-check purple-color" aria-hidden="true"></i>
                  Skil dig ud fra mængden med en Talent profil
                </li>
                <li>
                  <i class="fa fa-check purple-color" aria-hidden="true"></i>
                  Adgang til Excelerate Karriere Matching
                </li>
              </ul>
              <template v-else>
                <ul
                  class="text-left pl-0"
                  v-if="talentListContent && talentListContent.length > 0"
                >
                  <li v-for="item in talentListContent" :key="item.id">
                    <i class="fa fa-check pink-color" aria-hidden="true"></i>
                    {{ getTalentListItem(item) }}
                  </li>
                </ul>
                <ul class="text-left pl-0" v-else>
                  <li>
                    <i class="fa fa-check pink-color" aria-hidden="true"></i>
                    Unlock Excelerate Career Matching
                  </li>
                  <li>
                    <i class="fa fa-check pink-color" aria-hidden="true"></i>
                    Triple thesis collaboration chances
                  </li>
                  <li>
                    <i class="fa fa-check pink-color" aria-hidden="true"></i>
                    Boost profile to stand out
                  </li>
                </ul>
              </template>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button
          v-if="isZealandFlow"
            class="btn btn-primary RisingStar-ProfileOverview-Next mt-5 w-200"
            v-on:click="handleSubmit"
          >
          Næste
          </button>
          <button
            v-else
            class="btn btn-primary RisingStar-ProfileOverview-Next mt-5 w-200"
            v-on:click="handleSubmit"
          >
            {{
              pageContent && pageContent.formNextButton
                ? pageContent.formNextButton
                : "Next"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RisingStarProgressBar from "../RisingStarProgressBar.vue";
export default {
  name: "StudentProfileProgress",
  props: ["handleSubmit", "progress"],
  components: { RisingStarProgressBar },
  computed: {
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    isLoading() {
      return this.$store.getters.dynamicIsLoading;
    },
    pageContent() {
      return this.$store.getters.risingOverviewContent;
    },
    risingListContent() {
      return this.$store.getters.risingOverviewRisingListContent;
    },
    talentListContent() {
      return this.$store.getters.risingOverviewTalentListContent;
    },
  },
  methods: {
    getRisingListItem(item) {
      const obj = item.rising_data;
      return obj[1];
    },
    getTalentListItem(item) {
      const obj = item.talent_data;
      return obj[1];
    },
  },
};
</script>
<style>
.user-flow-logo {
  padding: 60px 0 40px 0;
  margin-top: 35px;
}

.user-flow {
  border-radius: 10px;
}
</style>
<style scoped>
.bg-grey {
  background-color: #dbdbdb;
  opacity: 1;
}

.overview-header {
  height: 100px;
  border-radius: 10px 10px 0 0;
}

ul {
  list-style: none;
}

ul li {
  /* max-width: 300px; */
  /* text-overflow: wrap; */
}

.spacing {
  padding: 50px 30px 30px 30px !important;
}

.mobile-flex {
  justify-content: start;
  column-gap: 40px;
  align-items: center;
}

.mobile-flex img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.parent_box {
  width: 50%;
  margin: auto;
}

@media (max-width: 767px) {
  .hide-mobile {
    display: none !important;
  }

  .parent_box {
    width: 100%;
  }

  .mobile-flex {
    justify-content: space-between;
    column-gap: 10px;
    margin-top: 40px;
  }

  .mobile-flex img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .mobile-bottom-space {
    margin-bottom: 60px;
  }

  .spacing {
    padding: 50px 0px 30px 0px !important;
  }
}
</style>
