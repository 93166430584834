<template>
  <div class="">
    <div class="input-group mb-3">
      <label>Hard Skill #{{ id }}</label>
      <autocomplete
        :key="11"
        :id="defaultKey"
        input-info='{"type":"hardSkills","skill":"skill"}'
        @blur="addCoreSkill($event, skill)"
        v-bind:default-value="defaultValue"
        :search="hardSkillsSource"
        :placeholder="placeholder[id - 1]"
        auto-select
        :get-result-value="getResultValue"
        class="hard-skills"
      ></autocomplete>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import { mapState } from 'vuex';
export default {
  name: 'CompetenceHardSkills',
  props: ['skills', 'skill', 'skillData', 'id', 'placeholder'],
  components: {
    Autocomplete,
  },
  data() {
    return {
      isCustom: false,
    };
  },
  computed:{
    defaultValue(){
      return this.skills ? this.skills[this.skill] : ''
    },
    defaultKey(){
      return Object.keys(this.skills)[this.id - 1]
    }
  },
  methods: {
    hardSkillsSource(input) {
      this.isCustom = true;
      let hsds = JSON.parse(JSON.stringify(this.skillData));
      // console.log(hsds);
      if (input.length < 1) {
        return [];
      }
      const itemsToRemove = Object.values(this.skills);

      itemsToRemove.forEach((element) => {
        const currentIndex = hsds.findIndex((obj) => obj.name === element);
        if (currentIndex > -1) {
          hsds.splice(currentIndex, 1);
        }
      });
      return hsds.filter((hsd) => {
        return hsd.name.toLowerCase().startsWith(input.toLowerCase());
      });
    },
    getResultValue(result) {
      this.isCustom = false;
      return result.name;
    },
    addCoreSkill(e, skill) {
      if (this.isCustom && e.target.value.length > 0) {
        const value = e.target.value;
        let words = value.split(' ');
        let capitalizedWords = words.map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        let finalString = capitalizedWords.join(' ');
        this.$emit('custom-hard-skill', finalString)
        this.$emit('update-hard-skill', e.target.id, finalString)
      }else {
        this.$emit('update-hard-skill', e.target.id, e.target.value)
      }
    },
  },
};
</script>

<style scoped>
.input-group div {
  width: 100%;
}
.input-group label {
  color: #333;
  font-size: 12px;
}
.user-form-buttons {
  position: absolute;
  right: 50px !important;
  bottom: 40px;
}
.required {
  border: 1px solid red;
}
</style>
