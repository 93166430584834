<template>
  <div class="experience-box" v-if="user && experience">
    <div class="inner-box">
      <h1 class="text-uppercase common-text">
        <template v-if="isZealandFlow">
          DIN ARBEJDSERFARING
        </template>
        <template v-else>
          {{ pageContent && pageContent.formHeader ? pageContent.formHeader : 'YOUR EXPERIENCE' }}
        </template>
      </h1>
      <rising-star-progress-bar :currentValue="progress"></rising-star-progress-bar>
      <h4 class="welcome-desc font-weight-normal">
        <template v-if="isZealandFlow">
          Fortæl os om dine arbejdserfaringer for at få din profil til at skille sig ud.
        </template>
        <template v-else>
          {{ pageContent && pageContent.formSubHeader ? pageContent.formSubHeader : 'Tell us a bit more about your student activites and work experience to make your profile stand out. You can always edit and/or add your work experience.' }}
        </template>
      </h4>
    </div>
    <div class="file-banner text-center text-black" v-if="cvName">
      <h1>
        <template v-if="isZealandFlow">
          Fil uploadet
        </template>
        <template v-else>
          File uploaded
        </template>
      </h1>
      <h4 class="font-weight-normal">
        <template v-if="isZealandFlow">
          Din fil er blevet uploadet og vil blive verificeret hurtigst muligt.
        </template>
        <template v-else>
          Your file has been submitted and will be processed shortly.
        </template>
      </h4>
      <div class="d-flex align-items-center justify-content-center">
        <p class="m-0">{{ cvName }}</p>
        <input v-if="isZealandFlow" type="button" class="btn pink-color" @click="removeFile" value="Fjern" />
        <input v-else type="button" class="btn pink-color" @click="removeFile" value="Remove" />
      </div>
    </div>
    <div v-else class="my-4">
      <h2>
        <template v-if="isZealandFlow">
          Upload dit CV
        </template>
        <template v-else>
          Upload your CV
        </template>
      </h2>
      <label for="file-upload" class="upload-button">Upload CV</label>
      <input id="file-upload" type="file" style="display: none" @change="uploadCv" />
      <div class="" v-show="isUploading">uploading...</div>
      <div class="" v-show="uploadError">{{ uploadError }}</div>
      <div class="" v-show="!hasSelectedFile && !user.hasUploadedCv">
        <h4 class="welcome-desc font-weight-normal mt-2 black">
          <template v-if="isZealandFlow">
            Eller udfyld din arbejdserfaring herunder.
          </template>
          <template v-else>
            Or fill in your work experience(s) below.
          </template>
        </h4>
      </div>
      <div class="pj-loading" v-show="!count">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
      <work-experience-item v-for="(item, index) in count" @remove-experience="removeExperience" :key="item" :id="item"
        :currentIndex="index" v-model="workExperienceData"></work-experience-item>
      <div class="button-container mt-4">
        <button class="btn add-button" @click="addCount">+</button>
      </div>
      <div class="alert alert-danger mt-3" v-if="errorText">
        {{ errorText }}
      </div>
    </div>
    <div class="text-right mt-5 inner-box">
      <div>
        <button class="btn btn-outline btn-new-outline Talent-Experience-Skip w-200" @click.prevent="handleSkip">
          <template v-if="isZealandFlow">
            Spring over
          </template>
          <template v-else>
            {{ pageContent && pageContent.formExperienceSkipButton ? pageContent.formExperienceSkipButton : 'Skip' }}
          </template>
        </button>
        <!-- <button
          class="btn btn-primary Talent-Experience-Next w-200"
          @click.prevent="handleSubmit('work experience')"
        >
          Next
        </button> -->
        <button class="btn btn-primary Talent-Experience-Next w-200" @click.prevent="validateForm" :disabled="isUpdating">
          <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else>
            <template v-if="isZealandFlow">
              Næste
            </template>
            <template v-else>
              {{ pageContent && pageContent.formExperienceNextButton ? pageContent.formExperienceNextButton : 'Next' }}
            </template>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';
import WorkExperienceItem from './WorkExperienceItem.vue';
import { base64ToString } from '../../../utils/utils';
import userService from '../../../services/userService'

let userId = base64ToString(localStorage.getItem('uid'))

export default {
  components: { WorkExperienceItem, RisingStarProgressBar },
  props: ['handleSubmit', 'progress', 'handleBack', 'handleSkip'],
  data() {
    return {
      count: [1], // this is an array and not a number
      errorText: null,
      workExperienceData: { 1: {} },
      uploadError: null,
      isUploading: false,
    };
  },
  mounted() {
    this.$store.dispatch('getIndustries')
  },
  computed: {
    isUpdating() {
      return this.$store.getters.userIsUpdating;
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
    user() {
      return this.$store.getters.studentData
    },
    pageContent() {
      return this.$store.getters.risingExperienceContent
    },
    experience() {
      const vm = this
      if (this.user.experience) {
        vm.workExperienceData = this.user.experience
        // vm.count =  
        const experienceKeys = Object.keys(this.user.experience)
        vm.count = experienceKeys
      }
      return true
    },

    cvName() {
      return this.user.cvName?.split("/")[
        this.user.cvName.split("/").length - 1
      ];
    },

  },
  methods: {
    uploadCv(event) {
      this.file = event.target.files[0];
      this.uploadError = null
      this.isUploading = true
      const formData = new FormData();
      formData.append("cvName", this.file);
      formData.append("hasUploadedCv", 1);
      formData.append("step", 2);
      // formData.append('showGrades', 1)
      formData.append('currentProgress', this.user.currentProgress + 30)
      this.$store.dispatch('uploadCv', formData).then(() =>
        this.$store.dispatch('getStudentData', userId).then(() => { this.isUploading = false }
        )
      ).catch((err) => {
        this.uploadError = err.response.data.message
        // this.isUploading = false
      })

      // // if(this.file.type)
      // this.$emit('file-selected', this.file);
    },
    // todo - not sure whay I decided putting all of this in modules was a good idea
    removeFile() {
      userService.removeCv().then(() => {
        this.$store.dispatch("getStudentData", userId);
      })
        .catch((err) => {
          console.log(err, "err");
        });

      // confirm('Are you sure you want to remove the uploaded file?')
      // this.user.hasUploadedTranscript = false;
      // this.hasSelectedFile = false;
      // this.$emit('remove-file');
    },
    addCount() {
      // console.log(this.workExperienceData, 'this.workExperienceData')
      const newElem = Math.max(...Object.keys(this.workExperienceData)) + 1
      this.workExperienceData[newElem.toString()] = {}
      // this.count.push(newElem.toString())
      this.count.push(newElem)
      this.$forceUpdate()
      // this.workExperienceData[newElem] = {}
      // this.count.push(newElem)
    },
    removeExperience(id) {
      delete this.workExperienceData[id];
      const index = this.count.indexOf(id);
      if (index !== -1) {
        this.count.splice(index, 1); // Remove the item from count
      }
      // const expArray = this.workExperienceData
      // delete expArray[id]
      // this.workExperienceData = expArray
      // delete this.workExperienceData[id]

    },
    validateForm() {
      if (this.user.hasUploadedCv) return this.$emit('handle-submit', 'work experience')
      this.errorText = null;
      this.count.forEach((element) => {
        const currentObject = this.workExperienceData[element];
        if (
          !currentObject.description ||
          !currentObject.title ||
          !currentObject.employmentType ||
          !currentObject.company ||
          !currentObject.industry ||
          !currentObject.locationType ||
          !currentObject.startMonth ||
          !currentObject.startYear ||
          (!currentObject.workingHere &&
            (!currentObject.endMonth || !currentObject.endYear))
        ) {
          this.errorText = 'Please fill all the fields to proceed';
        }
      });
      if (!this.errorText) {
        // this.$emit('submit-form', 'work experience', this.workExperienceData)
        // { competencies: this.formData, currentProgress: +this.user.currentProgress + 20, step: 1 }
        const currentProgress = this.user.isExp ? +this.user.currentProgress : +this.user.currentProgress + 30
        this.$store.dispatch('updateStudentExperience', { experience: this.workExperienceData, currentProgress, step: 2 }).then(() => {
          this.$store.dispatch('getStudentData', userId).then(() => {
            this.$emit('handle-submit', 'work experience')
          })
        });

        // console.log("🚀 ~ file: WorkExperience.vue:135 ~ validateForm ~ this.workExperienceData:", this.workExperienceData)
      }
    },
  },
};
</script>

<style scoped>
.add-button {
  font-size: 34px;
  color: white;
  background: #5a2871;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.button-container {
  display: flex;
  justify-content: center;
}

.experience-box {
  /* padding: 50px 0px; */
}

.inner-box {
  padding: 0px 30px;
}

.btn-new-outline {
  padding: 0.375rem 0.75rem !important;
  width: 200px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .experience-box {
    /* padding: 50px 0px 30px 0px !important; */
  }

  .btn-new-outline {
    width: 120px;
  }

  .w-200 {
    /* width: 120px; */
    margin-top: 20px;
  }

  .inner-box {
    padding: 0px 0px;
  }
}

.upload-btn {
  display: inline-block;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.upload-btn:hover {
  background-color: #3e8e41;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer !important;
}

.upload-button {
  color: white;
  margin: 15px 0;
  width: 350px;
  cursor: pointer !important;
  border: none;
  background-color: #5a2871;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
}

.file-banner {
  width: 493px;
  background: #f8f8f8;
  margin: 20px auto;
  padding: 5%;
}
</style>
