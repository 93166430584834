<template>
  <div>
    <div class="experience-container d-flex align-items-center p-4"
      :class="{ 'dark-bg': id % 2 === 0, 'light-bg': id % 2 != 0 }">
      <div class="left-container" :class="Object.keys(this.value).indexOf(id) === 0 ? 'col-md-12' : 'col-md-11'">
        <div class="d-flex align-items-center form-box">
          <span class="form-id">#{{ currentIndex + 1 }}</span>
          <div class="form-group input-container text-left w-30">
            <label for="">
              <template v-if="isZealandFlow">
                Titel
              </template>
              <template v-else>
                {{ pageContent && pageContent.formTitleLabel ? pageContent.formTitleLabel : 'Title' }}
              </template>
            </label>
            <input type="text" v-model="value[id].title" class="form-control form-control-lg"
              :placeholder="pageContent && pageContent.formTitlePlaceholder ? pageContent.formTitlePlaceholder : 'E.g. Student Assistant'"
              autofocus required />
          </div>
          <div class="form-group input-container text-left">
            <label for="">
              <template v-if="isZealandFlow">
                Ansættelsestype
              </template>
              <template v-else>
                {{ pageContent && pageContent.formEmploymentLabel ? pageContent.formEmploymentLabel : 'Employment type' }}
              </template>
            </label>
            <select v-if="isZealandFlow" class="form-control" v-model="value[id].employmentType">
              <option value="" selected disabled>Vælg</option>
              <option v-for="(item, index) in danishEmploymentType" :key="index" :value="item.id">
                {{ item.value }}
              </option>
            </select>
            <select v-else class="form-control" v-model="value[id].employmentType">
              <option value="" selected disabled>
                <template v-if="isZealandFlow">
                  Vælg
                </template>
                <template v-else>
                  Select
                </template>
              </option>
              <option v-for="(item, index) in employmentType" :key="index" :value="item.id">
                {{ item.value }}
              </option>
            </select>
          </div>
          <div class="form-group input-container text-left">
            <label for="">
              <template v-if="isZealandFlow">
                Virksomhed/Organisation
              </template>
              <template v-else>
                {{ pageContent && pageContent.formCompanyLabel ? pageContent.formCompanyLabel : 'Company/organisation' }}
              </template>
            </label>
            <input type="text" v-model="value[id].company" class="form-control form-control-lg"
              :placeholder="pageContent && pageContent.formCompanyPlaceholder ? pageContent.formCompanyPlaceholder : 'E.g. Excelerate'"
              autofocus required />
          </div>
          <div class="form-group input-container text-left">
            <label for="">
              <template v-if="isZealandFlow">
                Lokationstype
              </template>
              <template v-else>
                {{ pageContent && pageContent.formLocationTypeLabel ? pageContent.formLocationTypeLabel : 'Location type' }}
              </template>
            </label>
            <select class="form-control" v-model="value[id].locationType">
              <option value="" selected disabled>
                <template v-if="isZealandFlow">
                  Vælg
                </template>
                <template v-else>
                  Select
                </template>
              </option>
              <option v-for="(item, index) in locationType" :key="index" :value="item.id">
                {{ item.value }}
              </option>
            </select>
          </div>
          <div class="form-group input-container text-left">
            <label for="">
              <template v-if="isZealandFlow">
                Industri
              </template>
              <template v-else>
                {{ pageContent && pageContent.formExperienceIndustryLabel ? pageContent.formExperienceIndustryLabel : 'Industry' }}
              </template>
            </label>
            <select class="form-control" v-model="value[id].industry">
              <option value="" selected disabled>
                <template v-if="isZealandFlow">
                  Vælg
                </template>
                <template v-else>
                  Select
                </template>
              </option>
              <option v-for="(role, index) in industries" :key="index" :value="role.industry_id">
                {{ role.industry }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex align-items-center form-box">
          <div class="form-group custom-control custom-switch input-container text-left w-30">
            <p>
              <template v-if="isZealandFlow">
                Jeg arbejder her nu
              </template>
              <template v-else>
                {{ pageContent && pageContent.formExperienceWorkingHere ? pageContent.formExperienceWorkingHere : 'I am currently working here' }}
              </template>
            </p>
            <label class="switch">
              <input type="checkbox" @change="updateWorkingHere($event, id)" v-model="value[id].workingHere" />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="form-group input-container text-left">
            <label for="">
              <template v-if="isZealandFlow">
                Start måned
              </template>
              <template v-else>
                {{ pageContent && pageContent.formExperienceStartMonthLabel ? pageContent.formExperienceStartMonthLabel : 'Start date month' }}
              </template>
            </label>
            <select class="form-control" v-model="value[id].startMonth">
              <option value="" selected disabled>
                <template v-if="isZealandFlow">
                  Vælg
                </template>
                <template v-else>
                  Select
                </template>
              </option>
              <option v-for="(item, index) in months" :key="index" :value="item.id">
                {{ item.value }}
              </option>
            </select>
          </div>
          <div class="form-group input-container text-left">
            <label for="">
              <template v-if="isZealandFlow">
                Start år
              </template>
              <template v-else>
                {{ pageContent && pageContent.formExperienceStartYearLabel ? pageContent.formExperienceStartYearLabel : 'Start date year' }}
              </template>
            </label>
            <select class="form-control" required v-model="value[id].startYear">
              <option value="" selected disabled>
                <template v-if="isZealandFlow">
                  Vælg
                </template>
                <template v-else>
                  Select
                </template>
              </option>
              <option v-for="(item, index) in years" :key="index" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group input-container text-left" v-if="!value[id].workingHere">
            <label for="">
              <template v-if="isZealandFlow">
                Slut måned
              </template>
              <template v-else>
                {{ pageContent && pageContent.formExperienceEndMonthLabel ? pageContent.formExperienceEndMonthLabel : 'End date month' }}
              </template>
            </label>
            <select class="form-control" required v-model="value[id].endMonth">
              <option value="" selected disabled>
                <template v-if="isZealandFlow">
                  Vælg
                </template>
                <template v-else>
                  Select
                </template>
              </option>
              <option v-for="(item, index) in months" :key="index" :value="item.id">
                {{ item.value }}
              </option>
            </select>
          </div>
          <div class="form-group input-container text-left" v-if="!value[id].workingHere">
            <label for="">
              <template v-if="isZealandFlow">
                Slut år
              </template>
              <template v-else>
                {{ pageContent && pageContent.formHeader ? pageContent.formHeader : 'End date year' }}
              </template>
            </label>
            <select class="form-control" required v-model="value[id].endYear">
              <option value="" selected disabled>
                <template v-if="isZealandFlow">
                  Vælg
                </template>
                <template v-else>
                  Select
                </template>
              </option>
              <option v-for="(item, index) in years" :key="index" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex align-items-center flex-grow-1">
          <div class="form-group text-left w-100">
            <label for="">
              <template v-if="isZealandFlow">
                Beskrivelse
              </template>
              <template v-else>
                {{ pageContent && pageContent.formExperienceDescriptionLabel ? pageContent.formExperienceDescriptionLabel : 'Description' }}
              </template>
            </label>
            <!-- <plain-text-editor
              :placeholder="pageContent && pageContent.formExperienceDescriptionPlaceholder ? pageContent.formExperienceDescriptionPlaceholder : 'Describe your job, responsibilities, and role(s) here.'"
              v-model="value[id].description"
            ></plain-text-editor> -->
            <textarea v-if="isZealandFlow" class="w-100" placeholder="Beskriv dit job, ansvarsområder og rolle(r)."
              v-model="value[id].description" @keyup="validateCharectors"
              :class="{ 'limit-exceeded': isCharactersLimitExceeded }" rows="10"></textarea>
            <textarea v-else class="w-100" placeholder="Describe your job, responsibilities, and role(s) here."
              v-model="value[id].description" @keyup="validateCharectors"
              :class="{ 'limit-exceeded': isCharactersLimitExceeded }" rows="10"></textarea>
            <div class="charector-counter">
              <template v-if="isZealandFlow">
                Anslag {{ characters }}/ 2000
              </template>
              <template v-else>
                Characters {{ characters }}/ 2000
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1 right-container" v-if="Object.keys(this.value).indexOf(id.toString()) !== 0">
        <button class="btn button-pink"
          @click="handleDelete(id)">{{ pageContent && pageContent.formExperienceRemoveButton ? pageContent.formExperienceRemoveButton : 'Remove' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import plainTextEditor from '../../plainTextEditor.vue';


export default {
  // components: { plainTextEditor },
  name: 'workExperienceItem',
  data() {
    return {
      employmentType: [
        { id: 7, value: 'Apprenticeship' },
        { id: 5, value: 'Contract' },
        { id: 4, value: 'Freelance' },
        { id: 1, value: 'Full-time' },
        { id: 6, value: 'Internship' },
        { id: 2, value: 'Part-time' },
        { id: 3, value: 'Self-Employed' },
        { id: 8, value: 'Seasonal' },
      ],
      danishEmploymentType: [
        { id: 7, value: 'Mesterlære' },
        { id: 5, value: 'Kontrakt' },
        { id: 4, value: 'Freelance' },
        { id: 1, value: 'Fuldtid' },
        { id: 6, value: 'Praktik' },
        { id: 2, value: 'Deltid' },
        { id: 3, value: 'Selvstænding' },
        { id: 8, value: 'Sæsonarbejder' },
      ],
      locationType: [
        { id: 2, value: 'Hybrid' },
        { id: 1, value: 'On-site' },
        { id: 3, value: 'Remote' },
      ],
      months: [
        { id: 1, value: 'January' },
        { id: 2, value: 'February' },
        { id: 3, value: 'March' },
        { id: 4, value: 'April' },
        { id: 5, value: 'May' },
        { id: 6, value: 'June' },
        { id: 7, value: 'July' },
        { id: 8, value: 'August' },
        { id: 9, value: 'September' },
        { id: 10, value: 'October' },
        { id: 11, value: 'November' },
        { id: 12, value: 'December' },
      ],
      years: [
        2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023
      ],
      characters: this.value[this.id].description ? this.value[this.id].description.length : 0,
      textLength: this.value[this.id].description ? this.value[this.id].description.length : 0,
      isCharactersLimitExceeded: false,
    };
  },
  props: ['id', 'value', 'currentIndex'],
  computed: {
    industries() {
      return this.$store.getters.industries
    },
    pageContent() {
      return this.$store.getters.risingExperienceContent
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  },
  methods: {
    handleDelete(id) {
      // let experiences = this.value
      // delete experiences[id]
      this.$emit('remove-experience', id)
      // todo - implement loader for removing the item.
      // this.$store.dispatch('removeExperienceItem', we'll implement it here)
      // this.$store.dispatch('updateUserDetails', {...this.value, [this.value.workExperiences]: experiences});
    },

    // //had to force an update, vue is funny sometimes
    updateWorkingHere(event, id) {
      this.value[id].workingHere = event.target.checked
      this.$nextTick(() => {
        this.$forceUpdate();
      });

    },
    validateCharectors() {
      this.characters = this.value[this.id].description ? this.value[this.id].description.length : 0;
      const totalLimit = 2000
      if (this.characters > totalLimit) {
        this.isCharactersLimitExceeded = true;
      } else {
        this.isCharactersLimitExceeded = false;
      }
    }
  },
};
</script>

<style scoped>
textarea {
  border: 1px solid #ccc;
  width: 100%;
  display: block;
  padding: 1rem;
  outline: none;
}

.charector-counter {
  text-align: right;
  font-size: 12px;
  margin-top: 2px;
}

textarea:focus-visible {
  border: 1px solid #ccc;
}

textarea.limit-exceeded {
  border-color: red;
}

.experience-container {
  width: 100%;
  /* margin-top: 50px; */
}

.dark-bg {
  background: #fff;
}

.light-bg {
  background: #F6F6F6;
}

.left-container {
  padding-right: 30px;
  text-align: left;
}

.right-container {
  margin-left: -30px;
}

.form-box {
  column-gap: 10px;
}

.input-container {
  width: 19%;
}

.form-id {
  color: #5a2871;
  font-size: 18px;
  font-weight: 600;
}

.w-30 {
  width: 30% !important;
}

@media (max-width: 767px) {
  .experience-container {
    flex-direction: column;
    padding: 20px 0px !important;
    margin-top: 30px;
  }

  .right-container {
    margin-left: 0;
  }

  .left-container {
    padding-right: 15px;
  }

  .form-box {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 0;
  }

  .input-container {
    width: 48%;
  }

  .w-30 {
    width: 100% !important;
  }

  .custom-switch {
    padding-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 998px) {
  .experience-container {
    flex-direction: column;
    padding: 20px 0px !important;
    margin-top: 30px;
  }

  .right-container {
    margin-left: 0;
  }

  .left-container {
    padding-right: 15px;
  }

  .form-box {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 0;
  }

  .input-container {
    width: 48%;
  }

  .w-30 {
    width: 100% !important;
  }

  .custom-switch {
    padding-left: 0;
    margin-top: 10px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #3aa948;
}

input:focus+.slider {
  box-shadow: 0 0 1px #3aa948;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-switch p {
  color: #512871 !important;
  margin: 0px 0px 7px !important;
  font-weight: 500 !important;
  margin-bottom: 0;
}

.custom-switch {
  column-gap: 10px;
}

input,
select {
  height: 44px !important;
}

/* label > p{
  color: #5A2871!important;
} */
</style>
