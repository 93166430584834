<template>
  <div class="">
    <div class="pj-loading" v-if="isLoading">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <div class="study-wrapper" v-else>
      <h1 class="text-uppercase black mobile-text">
        <template v-if="isZealandFlow">DIN STUDIEBAGGRUND</template>
        <template v-else>YOUR STUDY BACKGROUND</template>
      </h1>
      <rising-star-progress-bar :currentValue="progress" />
      <h4 class="welcome-desc font-weight-normal black container-narrow mx-auto">
        <template v-if="isZealandFlow">Fortæl os om din studiebaggrund</template>
        <template v-else>Tell us a bit more about your study background.</template>
      </h4>
      <div class="" v-if="!isBachelor">
        <h3 class="mt-5 black">
          <span class="counter">1</span>
          <template v-if="isZealandFlow"> Din Bachelor</template>
          <template v-else> Your Bachelor's degree</template>
        </h3>
        <h4 class="welcome-desc font-weight-normal mt-2 black">
          <template v-if="isZealandFlow">Vælg din bachelor her.</template>
          <template v-else>Find and select your Bachelor's degree.</template>
        </h4>
        <div class="user-form-wizard">
          <div class="form-group text-left container-narrow mx-auto" v-if="universities">
            <label>
              <template v-if="isZealandFlow">Din uddannelsesinstitution *</template>
              <template v-else>Your educational institution? *</template>
            </label>
            <select class="form-control text-secondary" v-model="bachelorData.institution"
              @change="getCurrentUniversityData()">
              <option value="null" disabled v-if="universities.universities.length !== 1">
                <template v-if="isZealandFlow">
                  Vælg fra listen
                </template>
                <template v-else>
                  Select from list
                </template>
              </option>
              <option v-for="(institute, index) in universities.universities" :key="index" :value="{
                uid: institute.id,
                id: institute.university_id,
                name: institute.university_name,
              }">
                {{ institute.university_name }}
              </option>
              <!-- <option value="other">Other</option> -->
            </select>
          </div>
          <div class="pj-loading" v-show="!universityLoaded &&
            bachelorData.institution &&
            bachelorData.institution.name !== 'Other'
            ">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>

          <div class="form-group text-left container-narrow mx-auto" v-if="bachelorData.institution &&
            universityLoaded &&
            bachelorData.institution.name != 'Other'
            ">
            <label>
              <template v-if="isZealandFlow">Din Bachelor? *</template>
              <template v-else>Your Bachelor's degree? *</template>
            </label>
            <autocomplete v-if="isZealandFlow" ref="bachelorAutocomplete" :search="searchArea"
              :placeholder="+bachelorData.hasCustomProgramme === 1 ? 'Ikke på listen' : 'Search and select'"
              :disabled="+bachelorData.hasCustomProgramme === 1" aria-label="Search and select" class="hard-skills"
              :get-result-value="getResultValue" @keydown.enter.prevent @submit="setBachelorAreaForUser"
              v-bind="{ 'default-value': +bachelorData.hasCustomProgramme === 1 ? '' : bachelorData.area }">
            </autocomplete>
            <autocomplete v-else ref="bachelorAutocomplete" :search="searchArea"
              :placeholder="+bachelorData.hasCustomProgramme === 1 ? 'Not on the list' : 'Search and select'"
              :disabled="+bachelorData.hasCustomProgramme === 1" aria-label="Search and select" class="hard-skills"
              :get-result-value="getResultValue" @keydown.enter.prevent @submit="setBachelorAreaForUser"
              v-bind="{ 'default-value': +bachelorData.hasCustomProgramme === 1 ? '' : bachelorData.area }">
            </autocomplete>
            <div class="custom-programme-div">
              <button @click.prevent="setBachelorCustomProgramme" class="btn my-button-right">Not on the list?</button>
            </div>
          </div>
          <div class="form-group text-left container-narrow mx-auto" v-if="+bachelorData.hasCustomProgramme === 1">
            <label>
              <template v-if="isZealandFlow">
                Din studiebaggrund?
              </template>
              <template v-else>
                Your study degree? *
              </template>
            </label>
            <input type="text"
              :placeholder="isZealandFlow ? 'F.eks. Software Udvikling' :'E.g. Bsc in Software development'"
              class="form-control form-control-lg" v-model="bachelorData.area" required />
          </div>
          <div class="form-group text-left container-narrow mx-auto" v-if="user.hasBachelorSubProgramme &&
            bachelorData.institution.name === 'Roskilde University'
            ">
            <label>Specialization course #1? *</label>
            <autocomplete :search="searchSubProgramme" placeholder="Search and select" aria-label="Search and select"
              class="hard-skills" :get-result-value="getSubProgrammeValue" @submit="setFirstSpecialization"
              v-bind:default-value="bachelorData.specializationCourseOne"></autocomplete>
          </div>
          <div class="form-group text-left container-narrow mx-auto" v-if="user.hasBachelorSubProgramme &&
            bachelorData.institution.name === 'Roskilde University'
            ">
            <label>Specialization course #2? *</label>
            <autocomplete :search="searchSubProgramme" placeholder="Search and select" aria-label="Search and select"
              class="hard-skills" :get-result-value="getSubProgrammeValue" @submit="setSecondSpecialization"
              v-bind:default-value="bachelorData.specializationCourseTwo"></autocomplete>
          </div>
          <div class="" v-if="bachelorData.institution &&
            bachelorData.institution.name == 'Other'
            ">
            <div class="form-group text-left container-narrow mx-auto">
              <label for="">Name of educational institution</label>
              <input type="text" class="form-control form-control-lg"
                v-model="bachelorData.customBachelorInstitutionName"
                placeholder="Enter the name of your educational institution" />
            </div>
            <div class="form-group text-left container-narrow mx-auto">
              <label for="">Study program</label>
              <input type="text" class="form-control form-control-lg" v-model="bachelorData.customBachelorStudyProgram"
                placeholder="Enter the name of your study program" />
            </div>
          </div>
          <div class="form-group text-left container-narrow mx-auto">
            <label>
              <template v-if="isZealandFlow">
                Hvilket år blev du færdig?
              </template>
              <template v-else>
                Which year did you graduate? *
              </template>
            </label>
            <select v-model="bachelorData.graduationYear" class="form-control">
              <option value="null" disabled>
                <template v-if="isZealandFlow">
                  Vælg fra listen
                </template>
                <template v-else>
                  Select from list
                </template>
              </option>
              <option v-for="(year, index) in years" :key="index">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="alert alert-danger mt-3" v-if="errorBag.length > 0">
          Please fill/select your {{ errorBag.join(", ") }}
        </div>
      </div>

      <h3 class="mt-5 black">
        <span v-if="isBachelor" class="counter">1</span>
        <span v-else class="counter">2</span>
        <template v-if="isZealandFlow"> Udvidet matching</template>
        <template v-else> Course results matching</template>


      </h3>

      <h4 class="welcome-desc font-weight-normal mt-2 black container-narrow mx-auto">
        <template v-if="isZealandFlow">Ønsker du at gøre dine kursusresultater synlige for de virksomheder, som kræver
          det
          til matching?</template>
        <template v-else>Want to make your course results visible to companies that requires it?</template>
      </h4>
      <div
        class="form-group custom-control custom-switch input-container d-flex align-items-center justify-content-center">
        <p>
          <template v-if="isZealandFlow">Vis kursusresultater</template>
          <template v-else>Show course results</template>
        </p>
        <label class="switch">
          <input type="checkbox" v-model="courseData.showGrades" />
          <span class="slider round"></span>
        </label>
      </div>
      <h3 class="mt-5 black" v-if="isBachelor">
        <span class="counter">2</span> Your Bachelor's degree courses
      </h3>
      <h3 class="mt-5 black" v-else>
        <span class="counter">3</span>
        <template v-if="isZealandFlow"> Din nuværende uddannelse</template>
        <template v-else> Your Master's degree courses</template>


      </h3>

      <h4 class="welcome-desc font-weight-normal mt-2 black container-narrow mx-auto" v-if="isBachelor">
        Fill in information about your Bachelor's degree courses. Upload your
        transcript of passed subject to autofill.
      </h4>
      <h4 class="welcome-desc font-weight-normal mt-2 black container-narrow mx-auto" v-else>
        <template v-if="isZealandFlow">Udfyld kurser på din nuværende uddannelse automatisk ved at uploade dit
          karakterblad med beståede kurser.</template>
        <template v-else>Fill in information about your Master's degree courses. Upload your transcript of passed
          subject
          to autofill.</template>
      </h4>
      <div class="file-banner text-center text-black" v-if="user.hasUploadedTranscript">
        <h1>
          <template v-if="isZealandFlow">
            Fil uploadet
          </template>
          <template v-else>
            File uploaded
          </template>
        </h1>
        <h4 class="font-weight-normal">
          <template v-if="isZealandFlow">
            Din fil er blevet uploadet og vil blive verificeret hurtigst muligt.
          </template>
          <template v-else>
            Your file has been submitted and will be processed shortly.
          </template>
        </h4>
        <div class="d-flex align-items-center justify-content-center">
          <p class="m-0">{{ transcriptName }}</p>
          <input v-if="isZealandFlow" type="button" class="btn pink-color" @click="removeFile" value="Fjern" />
          <input v-else type="button" class="btn pink-color" @click="removeFile" value="Remove" />
        </div>
      </div>
      <div v-else class="">
        <!-- <div v-if="false" class=""> -->
        <label for="file-upload" class="upload-button">
          <template v-if="isZealandFlow">Upload karakterblad</template>
          <template v-else>Upload transcript</template>
        </label>
        <input id="file-upload" type="file" style="display: none" @change="uploadTranscript" />
      </div>
      <div class="" v-show="isUploading">uploading...</div>
      <div class="" v-show="uploadError">{{ uploadError }}</div>
      <div class="" v-show="!hasSelectedFile && !user.hasUploadedTranscript">
        <h4 class="welcome-desc font-weight-normal mt-2 black" v-if="isBachelor">
          <template v-if="isZealandFlow">Eller udfyld dine kurser herunder.</template>
          <template v-else>Or fill in your Bachelor's courses below.</template>
        </h4>
        <h4 class="welcome-desc font-weight-normal mt-2 black" v-else>
          <template v-if="isZealandFlow">Eller udfyld dine kurser herunder.</template>
          <template v-else>Or fill in your Master's courses below.</template>
        </h4>
        <div class="">
          <label>
            <template v-if="isZealandFlow">
              Hvilket semester er du på?
            </template>
            <template v-else>
              Which semester are you in?
            </template>
          </label>
          <select class="form-control text-secondary w-300 m-auto" v-model="currentUserSemester">
            <option disabled value="" selected>
              <template v-if="isZealandFlow">
                Vælg fra listen
              </template>
              <template v-else>
                Select from list
              </template>
            </option>
            <template v-if="isBachelor">
              <option v-for="(semester, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="index">
                {{ semester }}
              </option>
            </template>
            <template v-else>
              <option v-for="(semester, index) in [1, 2, 3, 4]" :key="index">
                {{ semester }}
              </option>
            </template>
          </select>
        </div>
        <div class="pj-loading" v-if="settingSemester">
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
        <semester-item v-else v-for="(item, index) in semesterCount" v-model="courseData" :key="index" :id="index + 1"
          :isBachelor="isBachelor" :semId="item" :universities="universities" :handleDelete="handleDelete"
          @add-course="addCourseItem"></semester-item>
      </div>
      <div class="alert alert-danger mt-3" v-if="errorArray.length > 0">
        Please fill {{ errorArray.join(", ") }} for all semesters and courses.
      </div>
      <div class="d-flex justify-content-end mt-5 mb-5 inner-box">
        <div class="">
          <button :disabled="isUploading" class="btn btn-outline btn-new-outline RisingStar-StudyBackground-Skip w-200"
            @click="handleSkip">
            <template v-if="isZealandFlow">Spring over</template>
            <template v-else>Skip</template>
          </button>
          <button :disabled="isUploading || isUpdating" class="btn btn-primary w-200 RisingStar-StudyBackground-Next"
            @click.prevent="validateForm">
            <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-else>
              <template v-if="isZealandFlow">Næste</template>
              <template v-else>Next</template>
            </span>
          </button>
        </div>
      </div>

      <div v-if="showGradesModal">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <h1>
                      <template v-if="isZealandFlow">

                        Hov, det er lidt akavet
                      </template>
                      <template v-else>

                        WELL THIS IS AWKWARD
                      </template>
                    </h1>
                    <p>
                      <template v-if="isZealandFlow">

                        Vi kunne ha' svoret på, at du var én, der ikke ville gå glip af en mulighed, for at øge chancen
                        for at komme tættere på virksohmederne? Den er på os :)
                      </template>
                      <template v-else>
                        We could have sworn you were someone who didn't want to
                        miss out on a collaboration opportunity. Our bad.

                      </template>
                      <br />
                      <br />
                      <template v-if="isZealandFlow">

                        Hvad hvis vi nu lige efterlader den her knap her? bare for en sikkerhedsskyld?
                      </template>
                      <template v-else>
                        We'll just leave this button below if you change your mind
                        then...
                      </template>
                    </p>
                    <div
                      class="form-group custom-control custom-switch input-container d-flex align-items-center justify-content-center">
                      <p class="font-weight-bold text-white">
                        <template v-if="isZealandFlow">

                          Vis kursusresultater
                        </template>
                        <template v-else>
                          Show grades

                        </template>
                      </p>
                      <label class="switch">
                        <input type="checkbox" v-model="courseData.showGrades" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div class="text-right">
                      <button class="btn white-button mt-3 Explorer-YourStudyBackground-Next w-200"
                        @click.prevent="handleGradesSubmit">
                        <template v-if="isZealandFlow">

                          Næste
                        </template>
                        <template v-else>

                          Next
                        </template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="showEctsModal">
        <transition name="modal">
          <div class="modal-mask" @click="showEctsModal = false">
            <div class="modal-wrapper">
              <div class="modal-dialog">
                <div class="modal-content">
                  <!-- <button
                  type="button"
                  class="close"
                  @click="showEctsModal = false"
                >
                  <span aria-hidden="true">&times;</span>
                </button> -->
                  <div class="modal-body">
                    <h1>OOPS!</h1>
                    <p>
                      <Strong>You are missing at least {{ totalPoints - sum }} ECTS
                        points</Strong>
                      <br />
                      <br />
                      Please fill in all the ECTS points you have acquired on
                      your current study programme
                    </p>
                    <div class="text-right">
                      <button class="btn white-button mt-3 w-200" @click="showEctsModal = false">
                        Add more courses
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import json_semesters from "@/json/semesters.json";

import apiInstance from "../../../axios/axios";

import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import SemesterItem from "./SemesterItem.vue";
import RisingStarProgressBar from "../../RisingStarProgressBar.vue";
import { base64ToString } from "../../../utils/utils";
import userService from "../../../services/userService";

let userId = base64ToString(localStorage.getItem("uid"));

export default {
  components: { Autocomplete, RisingStarProgressBar, SemesterItem },
  props: ["handleSubmit", "handleSkip", "errorText", "progress"],
  data() {
    return {
      universityData: {},
      bachelorData: {},
      courseData: {
        showGrades: false,
        semesterCourseCount: {
          1: [1],
          2: [1],
          3: [1],
          4: [1],
          5: [1],
          6: [1],
          7: [1],
          8: [1],
          9: [1],
          10: [1],
        },
        educationHistory: {
          1: { 1: {} },
          2: { 1: {} },
          3: { 1: {} },
          4: { 1: {} },
          5: { 1: {} },
          6: { 1: {} },
          7: { 1: {} },
          8: { 1: {} },
          9: { 1: {} },
          10: { 1: {} },
        },
      },
      totalPoints: 0,
      sum: 0,
      semesters: json_semesters,
      loading: false,
      universityLoaded: false,
      hasSelectedFile: false,
      showEctsModal: false,
      file: null,
      errorArray: [],
      errorBag: [],
      years: [
        2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
        2022, 2023, 2024
      ],
      subProgrammeList: [],
      showGradesModal: false,
      isUploading: false,
      uploadError: null,
      currentUserSemester: null,
      settingSemester: false,
    };
  },
  mounted: function () {
    this.$store.dispatch("getUniversities");
    if (this.bachelorData.institution?.uid) {
      //todomo
      if (this.user.institution?.uid) {
        this.getCurrentUniversityData();
        this.loading = false;
      }
    }
  },
  watch: {
    currentUserSemester(newValue, oldValue) {
      // if(this.user.currentSemester){}
      if (newValue &&
        (!this.user.currentSemester || this.user.currentSemester) &&
        newValue != this.user.currentSemester
      ) {
        this.settingSemester = true;
        userService
          .updateStudent({
            ...this.user,
            currentSemester: this.currentUserSemester,
          })
          .then(() => {
            if (!this.isBachelor && !this.validBachelorEducationData(this.bachelorData)) {
              this.$store
                .dispatch("updateBachelorEducationDetails", this.bachelorData)
                .then(() => {
                  this.$store.dispatch("getStudentData", userId).then(() => {
                    this.settingSemester = false;
                  });
                });
            } else {
              this.$store.dispatch("getStudentData", userId).then(() => {
                this.settingSemester = false;
              });
            }
          })
          .catch(() => {
            this.settingSemester = false;
            this.errorArray.push("Something went wrong, please try again");
          });
      }
    },
  },
  computed: {
    isUpdating() {
      return this.$store.getters.userIsUpdating;
    },
    isBachelor() {
      return !this.user.degree.toLowerCase().includes("master");
    },
    transcriptName() {
      return this.user.transcriptName?.split("/")[
        this.user.transcriptName.split("/").length - 1
      ];
    },
    user() {
      const vm = this;
      const data = this.$store.getters.studentData;
      // if(data.bachelor) {
      return data;
      // }
      // else{
      //   return {...data, bachelor: {}}
      // }
      // return this.$store.getters.studentData
    },
    isLoading() {
      const vm = this;
      // this is a failsafe because if some isse that's happening with firebase
      // todo remove this within the year, maybe by October 23 - delayed because I am srill dealinf with shit in november
      vm.currentUserSemester = this.user.currentSemester;
      if (this.user.courses && !this.user.semesterCount) {
        vm.courseData.educationHistory = JSON.parse(this.user.courses);
        vm.courseData.semesterCourseCount = {
          1: [1],
          2: [1],
          3: [1],
          4: [1],
          5: [1],
          6: [1],
          7: [1],
          8: [1],
          9: [1],
          10: [1],
        };
        vm.courseData.showGrades = this.user.showGrades
          ? this.user.showGrades
          : false;
      }
      if (this.user.courses && this.user.semesterCount) {
        vm.courseData.educationHistory = JSON.parse(this.user.courses);
        vm.courseData.semesterCourseCount = JSON.parse(this.user.semesterCount);
        vm.courseData.showGrades = this.user.showGrades
          ? this.user.showGrades
          : false;
        // vm.workExperienceData = this.user.experience
        // vm.count =
        // const experienceKeys = Object.keys(this.user.experience)
        // vm.count = experienceKeys
      }
      //introduced involuntary bug, had to rewrite the below logic, please don't fuck it up again
      if (!this.user.isCourses && !this.user.hasUploadedTranscript) {
        for (const key in vm.courseData.educationHistory) {
          for (const skey in vm.courseData.educationHistory[key]) {
            vm.courseData.educationHistory[key][skey].institution =
              this.user.institution;
            // ! this is part of the new implementation since we now accept other programme name as well as other institution name
            vm.courseData.educationHistory[key][skey].area = this.user.area
              ? this.user.area
              : this.user.otherProgrammeName;
          }
        }
      }
      if (this.user.bachelor) {
        vm.bachelorData = { ...this.user.bachelor };
      }
      return (
        this.$store.getters.userIsLoading ||
        this.$store.getters.generalIsLoading
      );
    },
    semesterCount() {
      const arrLength = +this.user.currentSemester
        ? +this.user.currentSemester
        : this.currentUserSemester;
      const count = [];
      for (let i = 1; i <= arrLength; i++) {
        count.push(i);
      }
      return count;
    },
    isLoaded() {
      // var vm = this;
      // var result = false;
      // if (this.user.userId !== null) {
      //   var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
      //   vm.user = userInfo;
      // }
      return true;
    },
    universities: function () {
      return this.$store.getters.universities;
    },
    isZealandFlow() {
      return this.$store.getters.isZealandFlow
    },
  },
  methods: {
    uploadTranscript(event) {
      this.file = event.target.files[0];
      this.uploadError = null;
      this.isUploading = true;
      const formData = new FormData();
      formData.append("transcriptName", this.file);
      formData.append("hasUploadedTranscript", 1);
      formData.append("step", 0);
      // formData.append('showGrades', 1)
      formData.append(
        "currentProgress",
        this.user.currentProgress ? this.user.currentProgress + 30 : 30
      );
      this.$store
        .dispatch("uploadTranscript", formData)
        .then(() => {
          if (!this.isBachelor && this.validateBachelorEducationFields(true)) {
            this.$store
              .dispatch("updateBachelorEducationDetails", this.bachelorData)
              .then(() => {
                this.$store.dispatch("getStudentData", userId).then(() => {
                  // this.$emit('handle-submit', 'courses')
                  this.isUploading = false;
                });
              });
          } else {
            this.$store.dispatch("getStudentData", userId).then(() => {
              // this.$emit('handle-submit', 'courses')
              this.isUploading = false;
            });
          }
          // this.$store.dispatch('getStudentData', userId).then(() => { this.isUploading = false })
        })
        .catch((err) => {
          this.uploadError = err.response.data.message;
          this.isUploading = false;
        });

      // // if(this.file.type)
      // this.$emit('file-selected', this.file);
    },
    //this method will be removed in package 11
    async getCurrentUniversityData() {
      // this.user.secondaryArea = null;
      // this.user.area = null;
      this.universityLoaded = false;
      try {
        const res = await apiInstance.get(
          `getUniversityDataListing/${this.bachelorData.institution.uid}`
        );
        if (res.status == 200) {
          // no idea wtf this data is now. Random naming with random data is what we have here
          this.universityLoaded = true;
          // console.log(res, 'fdfs');
          this.universityData = res.data.payload;
        }
      } catch (error) {
        console.log("err");
      }
    },
    searchArea(input) {
      const vm = this;
      this.user.customProgramme = true;
      if (input.length < 1) {
        return [];
      }
      return this.universityData.programmes.filter((area) => {
        return area.programme_name.toLowerCase().includes(input.toLowerCase());
      });
    },
    searchSubProgramme(input) {
      const vm = this;
      if (input.length < 1) {
        return [];
      }
      return this.subProgrammeList.filter((subProgramme) => {
        return subProgramme.subProgrammeName
          .toLowerCase()
          .includes(input.toLowerCase());
      });
    },
    getSubProgrammeValue(result) {
      return result.subProgrammeName;
    },
    setFirstSpecialization(value) {
      this.bachelorData.specializationCourseOne = value.subProgrammeName;
    },
    setSecondSpecialization(value) {
      this.user.bachelorSpecializationCourseTwo = value.subProgrammeName;
    },
    getResultValue(result) {
      this.user.customProgramme = false;
      return result.programme_name;
    },
    // setAreaForUser(area) {
    //   this.user.hasBachelorSubProgramme = false;
    //   if (area['subProgrammesList']) {
    //     this.user.hasBachelorSubProgramme = true;
    //     this.subProgrammeList = area['subProgrammesList'];
    //   }
    //   // now we get the students degree details once they select an area
    //   this.user.degree = Object.values(this.universityData.degrees).filter(
    //     (item) => item.id === area.degree_id
    //   )[0].degree_name;
    //   this.user.area = area.programme_name;
    // },
    setBachelorAreaForUser(area) {
      this.user.hasBachelorSubProgramme = false;
      if (area["subProgrammesList"]) {
        this.user.hasBachelorSubProgramme = true;
        this.subProgrammeList = area["subProgrammesList"];
        console.table(this.subProgrammeList);
        this.$forceUpdate();
      }
      // now we get the students degree details once they select an area
      this.user.bachelorDegree = Object.values(
        this.universityData.degrees
      ).filter((item) => item.id === area.degree_id)[0].degree_name;
      this.bachelorData.area = area.programme_name;
    },
    // setAreaForUser(area) {
    //   this.user.area = area.programme_name;
    // },
    // setSecondaryAreaForUser(area) {
    //   this.user.secondaryArea = area.programme_name;
    // },
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      if (!this.file) {
        return;
      }

      if (this.file.size > 20 * 1024 * 1024) {
        alert("File size exceeds 20 MB");
        return;
      }

      // const storageRef = firebase.storage().ref();
      // const fileRef = storageRef.child(`uploads/${this.file.name}`);
      // await fileRef.put(this.file);

      // alert('File uploaded successfully');
    },
    // setFirstSpecialization(area) {
    //   // this.user.bacbachelorSpecializationCourseOne = area.subProgrammeName;
    // },
    // setSecondSpecialization(area) {
    //   // this.user.bachelorSpecializationCourseTwo = area.subProgrammeName;
    // },
    validateBachelorEducationFields(noErr) {
      let isValid = true;
      this.errorBag = [];
      // Validate the educational institution field (select element)
      if (
        !this.bachelorData.institution ||
        (this.bachelorData.institution &&
          this.bachelorData.institution.name === "null")
      ) {
        isValid = false;
        this.isZealandFlow ? this.errorBag.push("Uddannelsesinstitution") : this.errorBag.push("educational institution");
      }

      // Validate the Bachelor's degree field (autocomplete input)
      if (
        this.bachelorData.institution &&
        this.universityLoaded &&
        this.bachelorData.institution.name != "Other" &&
        (!this.bachelorData.area || this.bachelorData.area.trim() === "")
      ) {
        isValid = false;
        this.isZealandFlow ? this.errorBag.push("bacheloruddannelse") : this.errorBag.push("bachelor's degree");

      }

      // Validate the Specialization course #1 field (autocomplete input)
      if (
        this.user.hasBachelorSubProgramme &&
        this.bachelorData.institution &&
        this.bachelorData.institution.name === "Roskilde University" &&
        (!this.bachelorData.specializationCourseOne ||
          this.bachelorData.specializationCourseOne.trim() === "")
      ) {
        isValid = false;
        this.isZealandFlow ? this.errorBag.push("specialekursus #1") : this.errorBag.push("specialization course #1");

      }

      // Validate the Specialization course #2 field (autocomplete input)
      if (
        this.user.hasBachelorSubProgramme &&
        this.bachelorData.institution &&
        this.bachelorData.institution.name === "Roskilde University" &&
        (!this.user.specializationCourseTwo ||
          this.user.specializationCourseTwo.trim() === "")
      ) {
        isValid = false;
        this.isZealandFlow ? this.errorBag.push("specialekursus #2") : this.errorBag.push("specialization course #2");

      }

      // Validate the Name of educational institution field (input)
      if (
        this.bachelorData.institution &&
        this.bachelorData.institution.name === "Other" &&
        (!this.bachelorData.customBachelorInstitutionName ||
          this.bachelorData.customBachelorInstitutionName.trim() === "")
      ) {
        isValid = false;
        this.isZealandFlow ? this.errorBag.push("Uddannelsesinstitution") : this.errorBag.push("educational institution");

      }

      // Validate the Study program field (input)
      if (
        this.bachelorData.institution &&
        this.bachelorData.institution.name === "Other" &&
        (!this.bachelorData.customBachelorStudyProgram ||
          this.bachelorData.customBachelorStudyProgram == null ||
          this.bachelorData.customBachelorStudyProgram.trim() === "")
      ) {
        isValid = false;

        this.isZealandFlow ? this.errorBag.push("Program") : this.errorBag.push("study program");

      }

      // Validate the graduation year field (select element)
      if (
        !this.bachelorData.graduationYear ||
        this.bachelorData.graduationYear === "null"
      ) {
        isValid = false;
        this.isZealandFlow ? this.errorBag.push("dimittendår") : this.errorBag.push("graduation year");
      }
      if (noErr) this.errorBag = []

      return isValid;
    },
    validateForm() {
      this.errorBag = [];
      this.errorArray = [];
      this.showGradesModal = false;
      this.sum = 0;
      let totalDeduction = 0;
      if (!this.isBachelor) {
        this.validateBachelorEducationFields();
      }
      if (this.user.hasUploadedTranscript && this.errorBag.length > 0) {
        return window.scrollTo(0, 0);
      }
      if (this.user.hasUploadedTranscript && !this.courseData.showGrades) {
        this.showGradesModal = true;
        return;
      } else if (
        this.user.hasUploadedTranscript &&
        this.courseData.showGrades
      ) {
        // this.handleSubmit('courses');
        this.$store.dispatch("setGrades", this.courseData.showGrades).then(() => this.$store.dispatch("getStudentData", userId)).then(() => {
          this.$emit("handle-submit", "courses")
        })
        return;
      }
      for (let key1 in this.courseData.educationHistory) {
        for (let key2 in this.courseData.educationHistory[key1]) {
          if (this.courseData.educationHistory[key1][key2].ects) {
            this.sum += +this.courseData.educationHistory[key1][key2].ects;
          }
          if (!this.courseData.educationHistory[key1][key2].ects) {
            this.errorArray.push("ECTS");
          }
          if (!this.courseData.educationHistory[key1][key2].year) {
            this.isZealandFlow ? this.errorArray.push("År") : this.errorArray.push("year");
          }
          // if (!this.courseData.educationHistory[key1][key2].institution) {
          //   this.errorArray.push("institution");
          // }
          // ! programmatically removing the fields based on the custom properties
          if (this.courseData.educationHistory[key1][key2].hasCustomUniversity) {
            this.courseData.educationHistory[key1][key2].institution = null;
            this.$forceUpdate() //! just a vue js thing for not rerendering the component unless it's a computed property
          } else {
            this.courseData.educationHistory[key1][key2].customInstitution = null
            this.$forceUpdate() //! just a vue js thing for not rerendering the component unless it's a computed property

          }
          if (this.courseData.educationHistory[key1][key2].hasCustomProgramme) {
            this.courseData.educationHistory[key1][key2].area = null;
            this.$forceUpdate() //! just a vue js thing for not rerendering the component unless it's a computed property
          } else {
            this.courseData.educationHistory[key1][key2].customProgramme = null
            this.$forceUpdate() //! just a vue js thing for not rerendering the component unless it's a computed property

          }
          if (!this.courseData.educationHistory[key1][key2].hasCustomUniversity && !this.courseData.educationHistory[key1][key2].institution) {
            this.errorArray.push("institution");
          }
          if (this.courseData.educationHistory[key1][key2].hasCustomUniversity && !this.courseData.educationHistory[key1][key2].customInstitution) {
            this.isZealandFlow ? this.errorArray.push("Uddannelsesinstitution") : this.errorArray.push("educational institution");

          }
          if (!this.courseData.educationHistory[key1][key2].hasCustomProgramme && !this.courseData.educationHistory[key1][key2].area) {
            this.isZealandFlow ? this.errorArray.push("area") : this.errorArray.push("area");

          }
          if (this.courseData.educationHistory[key1][key2].hasCustomProgramme && !this.courseData.educationHistory[key1][key2].customProgramme) {
            this.isZealandFlow ? this.errorArray.push("area") : this.errorArray.push("area");
          }
          if (!this.courseData.educationHistory[key1][key2].courseTitle) {
            this.isZealandFlow ? this.errorArray.push("Fag / Kursus titel") : this.errorArray.push("courses title");

          }
          if (!this.courseData.educationHistory[key1][key2].semester) {
            this.isZealandFlow ? this.errorArray.push("semester") : this.errorArray.push("semester");

          }
          if (!this.courseData.educationHistory[key1][key2].result) {
            this.isZealandFlow ? this.errorArray.push("resultat") : this.errorArray.push("result");

          }
        }
        if (
          this.user.currentSemester
            ? key1 > this.user.currentSemester - 1
            : key1 > this.currentUserSemester - 1
        ) {
          // console.log(key1 > this.user.currentSemester - 1);
          break;
        }
        if (Object.keys(this.courseData.educationHistory[key1]).length === 0) {
          totalDeduction += 30;
        }
      }
      this.totalPoints = +this.user.currentSemester
        ? +this.user.currentSemester
        : this.currentUserSemester;
      this.totalPoints = this.totalPoints * 30;
      this.totalPoints = this.totalPoints - 15 - totalDeduction;
      if (this.errorArray.length > 0) {
        this.errorArray = [...new Set(this.errorArray)];
        return;
      }
      if (
        (!this.file && this.sum < this.totalPoints) ||
        this.totalPoints <= 0
      ) {
        this.showEctsModal = true;
        return;
      }
      if (this.errorBag.length > 0) {
        return window.scrollTo(0, 0);
      }
      if (!this.courseData.showGrades) {
        this.showGradesModal = true;
        return;
      }

      // this.handleSubmit('courses');
      const currentProgress = this.user.isCourses
        ? +this.user.currentProgress
        : +this.user.currentProgress + 30;
      if (this.user.hasUploadedTranscript) return;
      this.$store
        .dispatch("updateEducationDetails", {
          ...this.courseData,
          currentProgress,
        })
        .then(() => {
          if (!this.isBachelor) {
            this.$store
              .dispatch("updateBachelorEducationDetails", this.bachelorData)
              .then(() => {
                this.$store.dispatch("setGrades", this.courseData.showGrades).then(() => {
                  this.$store.dispatch("getStudentData", userId).then(() => {
                    this.$emit("handle-submit", "courses");
                  });
                })
              });
          } else {
            this.$store.dispatch("setGrades", this.courseData.showGrades).then(() => {
              this.$store.dispatch("getStudentData", userId).then(() => {
                this.$emit("handle-submit", "courses");
              });
            })
          }
        });
    },
    //todo - handle this next
    handleGradesSubmit() {
      this.showGradesModal = false;
      const currentProgress = this.user.isCourses
        ? +this.user.currentProgress
        : +this.user.currentProgress + 30;
      if (this.user.hasUploadedTranscript) {
        return this.$store
          .dispatch("setGrades", this.courseData.showGrades)
          .then(() => {
            if (!this.isBachelor) {
              this.$store
                .dispatch("updateBachelorEducationDetails", this.bachelorData)
                .then(() => {
                  this.$store.dispatch("getStudentData", userId).then(() => {
                    this.$emit("handle-submit", "courses");
                  });
                });
            } else {
              this.$store.dispatch("setGrades", this.courseData.showGrades).then(() => {
                this.$store.dispatch("getStudentData", userId).then(() => {
                  this.$emit("handle-submit", "courses");
                });
              })
            }
          });
      }
      this.$store
        .dispatch("updateEducationDetails", {
          ...this.courseData,
          currentProgress,
        })
        .then(() => {
          if (!this.isBachelor) {
            this.$store
              .dispatch("updateBachelorEducationDetails", this.bachelorData)
              .then(() => {
                this.$store.dispatch("getStudentData", userId).then(() => {
                  this.$emit("handle-submit", "courses");
                });
              });
          } else {
            this.$store.dispatch("getStudentData", userId).then(() => {
              this.$emit("handle-submit", "courses");
            });
          }
        });
    },
    closeModal() {
      // this.showGradesModal = false;
    },
    // addCourseItem(semester) {
    //   this.user.semesterCourseCount[semester] += 1;
    //   this.user.educationHistory[semester][
    //     this.user.semesterCourseCount[semester]
    //   ] = {};
    // },
    // handleDelete(semester, courseId) {
    //   this.user.semesterCourseCount[semester] -= 1;
    //   delete this.user.educationHistory[semester][courseId];
    // },
    // DONT DELETE
    addCourseItem(semester) {
      // console.log(semester);
      const newItem =
        this.courseData.semesterCourseCount[semester][
        this.courseData.semesterCourseCount[semester].length - 1
        ] + 1;
      if (!newItem) {
        this.courseData.semesterCourseCount[semester].push(1);
        this.courseData.educationHistory[semester][1] = {};
        return;
      }
      this.courseData.semesterCourseCount[semester].push(newItem);
      this.courseData.educationHistory[semester][newItem] = {
        institution: this.user.institution,
        area: this.user.area,
      };
    },
    handleDelete(semester, courseId) {
      // console.log(semester, courseId);
      // console.log(this.user);
      this.courseData.semesterCourseCount[semester].splice(
        this.courseData.semesterCourseCount[semester].indexOf(courseId),
        1
      );
      // console.log(delete this.courseData.educationHistory[semester][courseId])
      // this.user.semesterCourseCount[semester].splice(
      //   this.user.semesterCourseCount[semester].indexOf(courseId),
      //   1
      // );
      // delete this.user.educationHistory[semester][courseId];
    },
    // onFileChange(event) {
    //   this.file = event.target.files[0];
    // },
    // async uploadFile() {
    //   if (!this.file) {
    //     return;
    //   }

    //   if (this.file.size > 20 * 1024 * 1024) {
    //     alert('File size exceeds 20 MB');
    //     return;
    //   }

    //   const storageRef = firebase.storage().ref();
    //   const fileRef = storageRef.child(`uploads/${this.file.name}`);
    //   await fileRef.put(this.file);

    //   alert('File uploaded successfully');
    // },
    removeFile() {
      this.$store
        .dispatch("removeTranscript")
        .then(() => {
          this.$store.dispatch("getStudentData", userId);
        })
        .catch((err) => {
          console.log(err, "err");
        });

      // confirm('Are you sure you want to remove the uploaded file?')
      // this.user.hasUploadedTranscript = false;
      // this.hasSelectedFile = false;
      // this.$emit('remove-file');
    },
    setBachelorCustomProgramme() {
      this.bachelorData.hasCustomProgramme = +this.bachelorData.hasCustomProgramme === 1 ? 0 : 1;
      this.$refs.bachelorAutocomplete.value = '';
      this.$forceUpdate()
    },
    validBachelorEducationData(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.autocomplete-input {
  color: #495057 !important;
}

.autocomplete .autocomplete-result-list li {
  color: #495057 !important;
}

.file-banner {
  width: 493px;
  background: #f8f8f8;
  margin: 20px auto;
  padding: 5%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0% 0% no-repeat padding-box;
  color: white;
  max-width: 500px;
  padding: 5%;
  border-radius: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #3aa948;
}

input:focus+.slider {
  box-shadow: 0 0 1px #3aa948;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-switch p {
  margin-bottom: 0;
  font-weight: 500 !important;
  color: #512871;
  margin: 0px 0px 7px !important;
}

.custom-switch {
  column-gap: 10px;
}

.inner-box {
  padding: 0px 30px;
}

.btn-new-outline {
  padding: 0.375rem 0.75rem !important;
  width: 200px;
  margin-right: 10px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer !important;
}

.upload-button {
  color: white;
  margin: 15px 0;
  width: 350px;
  cursor: pointer !important;
  border: none;
  background-color: #5a2871;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
}

/* @media (min-width: 799px) {
.study-wrapper {
  margin-top: 50px;
}
} */

.counter {
  background-color: #bc1e73;
  border-radius: 50%;
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: white;
}

@media (max-width: 499px) {
  .counter {
    background-color: #bc1e73;
    border-radius: 50%;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: white;
  }

  .file-banner {
    width: 100%;
  }
}

.white-button {
  background-color: white;
  color: #bc1e73;
}

.upload-btn {
  display: inline-block;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.upload-btn:hover {
  background-color: #3e8e41;
}

.w-300 {
  width: 300px;
}

@media (max-width: 767px) {
  .mobile-text {
    margin-bottom: 55px;
  }

  .w-200,
  .w-300 {
    /* width: auto */
    width: 120px;
  }

}

.text-white {
  color: white !important;
}
</style>
